import type { LinkInternal } from '@backmarket/http-api'

import { createHttpEndpoint } from '../utils'

interface MarketingContent {
  link?: {
    url: string
    text: string
  }
  alt: string
  urls?: {
    small: string
    large: string
  }
}

export interface ProductListPage {
  id: string
  landing_type: string
  links: Record<string, LinkInternal>
  urls: Record<string, string>
  text_seo: string
  title_seo: string
  /**
   * @deprecated don't use this field
   */
  description_seo: string
  displayed_title: string
  displayed_title_plural: string
  tagline: string
  breadcrumb: Array<{
    label: string
    url: string
  }>
  buying_guide: {
    id: number
    slug: string
    banner: string
    banner_alt: string
    title: string
    category: string
    summary: string
    link: LinkInternal
  }
  list_view_structure: {
    title: Array<{
      attribute: string
      suffix?: string
      prefix?: string
    }>
    table: Array<{
      label: string
      attributes: Array<{
        attribute: string
        suffix?: string
      }>
    }>
  }
  isVanishingDeals: boolean
  marketing_content?: {
    card?: MarketingContent
    list?: MarketingContent
  }
  popular_related_links: Array<{
    label: string
    url: string
  }>
  tags?: string[]
  slug: string
}

export interface Crumb {
  title: string
  link: LinkInternal
}

export interface SellerProductListPage {
  public_id: string
  company: string
  merchant_legal_name: string
  description: string
  links: Record<string, LinkInternal>
  address: {
    country: string
    city: string
    company: string
    postal_code: string
    street: string
    street2: string
    state_or_province: string
  }
  vat_number: string
  siret_number: string
  shop_cover_picture: string
  delta_aged: number
  slug: string
  display_merchant_infos: boolean
  country: string
  countryCode: string
  custom_data?: {
    japan?: {
      store_operation_manager: string
      store_security_officer: string
      konbini_phone_number: string
      konbini_email: string
      secondhand_dealer_number: string
      secondhand_dealer_number_issuing_prefecture: string
    }
    australia?: {
      secondhand_dealer_number: string
    }
  }
}

/**
 * Returns the product list page breadcrumbs
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/landing-page-api/definition#tag/landing-page/operation/get-landing_page-breadcrum}
 */
export const getBreadcrumb = createHttpEndpoint<Crumb[]>({
  method: 'GET',
  operationId: 'getLandingPageBreadcrumb',
  path: '/bm/landing_page/breadcrumb',
})

/**
 * Given a product list page, returns general information like text to display
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/landing-page-api/definition#tag/landing-page/operation/landingPageMainView}
 */
export const getProductListPage = createHttpEndpoint<ProductListPage>({
  method: 'GET',
  operationId: 'landingPageMainView',
  path: '/bm/landing_page',
})

/**
 * Given a seller product list page, returns general information like text to display
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/merchant-shop-api/definition}
 */
export const getSellerProductListPage =
  createHttpEndpoint<SellerProductListPage>({
    method: 'GET',
    operationId: 'sellerLandingPageMainView',
    path: '/bm/merchant/shop/:id',
  })
